import React from "react";

export function IHTSmallLogo(): React.ReactElement {
  return (
    <svg
      data-name="Italy Heritage Travel"
      height="25"
      viewBox="0 0 25 25"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-222.671 279.9c.163.281-.083.686-2.286 1.965l-.361 4.5-.744.432-.185-2.13-.405-1.376a7.923 7.923 0 00-1.126.632 7.888 7.888 0 00-1.111.9 7.945 7.945 0 00-1.653 2.335 7.932 7.932 0 00-.732 2.785 8.093 8.093 0 00.319 2.889 8.1 8.1 0 001.334 2.611 8.288 8.288 0 002.058 1.894 8.821 8.821 0 01-.443-.8 7.4 7.4 0 01-.655-2.008 6.172 6.172 0 01-.058-1.671 2.811 2.811 0 01-.775-1.04 2.652 2.652 0 01-.1-1.689 2.616 2.616 0 011.117 1.271c.018.045.034.089.05.134q.085-.252.191-.5a3.555 3.555 0 01.432-1.372 3.448 3.448 0 011.651-1.445 3.393 3.393 0 01-.419 2.153 3.487 3.487 0 01-.75.894 5.362 5.362 0 00-.5 1.286 5.345 5.345 0 00-.115 1.873c0 .041.01.082.016.123a2.543 2.543 0 01.409-.827 2.46 2.46 0 011.322-.885 2.5 2.5 0 01-.5 1.51 2.674 2.674 0 01-1.116.827 7.394 7.394 0 00.362 1.111 8.282 8.282 0 00.429.877 7.223 7.223 0 00.475.733 8.184 8.184 0 001.918.618 9.615 9.615 0 001.513.149 12.5 12.5 0 008.087-11.7 12.5 12.5 0 00-12.5-12.5 12.5 12.5 0 00-12.5 12.5 12.5 12.5 0 0012.5 12.5 12.539 12.539 0 002.665-.286 8.971 8.971 0 01-3-.984 9.1 9.1 0 01-4.256-4.987 9.374 9.374 0 01-.511-3.337c.006-.19.02-.38.038-.57a5.781 5.781 0 00-.322-.923 3.94 3.94 0 00-.1-.207 2.426 2.426 0 01-1.349-.177 2.282 2.282 0 01-1.048-1 2.234 2.234 0 011.443.149 2.291 2.291 0 01.578.392c-.024-.035-.047-.071-.072-.1a3.828 3.828 0 00-.73-.765l-.207-.155c-.073-.045-.148-.086-.221-.13l-.109-.065-.037-.016a3.66 3.66 0 01-1.264-.483 3.4 3.4 0 01-1.372-1.66 3.5 3.5 0 012.1.522 3.491 3.491 0 01.817.726l.083.049.141.083.13.1c.086.066.174.13.257.2l.031.03-.012-.053a2.243 2.243 0 01.21-1.414 2.209 2.209 0 01.7 1.245 2.4 2.4 0 01-.093 1.166c.054.084.105.17.152.257a5.923 5.923 0 01.471 1.1 9.3 9.3 0 01.51-1.611 9.343 9.343 0 014.566-4.765l-1.242-1.148-1.758-1.217.744-.432 4.087 1.918c2.2-1.279 2.677-1.292 2.84-1.011"
        data-name="Path 969"
        fill="#3e5335"
        transform="translate(240.026 -274.476)"
      />
    </svg>
  );
}
